export const stacks = [
  {
    name: "Favorites",
    photos: [
      "https://images.unsplash.com/photo-1506277886164-e25aa3f4ef7f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1619694770795-e21c58464159?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.pexels.com/photos/28642590/pexels-photo-28642590/free-photo-of-portrait-of-woman-sitting-on-modern-stool.jpeg",
      "https://images.pexels.com/photos/28642591/pexels-photo-28642591/free-photo-of-casual-portrait-with-soft-light-and-shadow.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2650090/pexels-photo-2650090.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },
  {
    name: "Korea",
    photos: [
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/28642591/pexels-photo-28642591/free-photo-of-casual-portrait-with-soft-light-and-shadow.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.unsplash.com/photo-1506277886164-e25aa3f4ef7f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ],
  },
  {
    name: "Faculty Party",
    photos: [
      "https://images.pexels.com/photos/5704720/pexels-photo-5704720.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4022812/pexels-photo-4022812.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2650090/pexels-photo-2650090.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },
  {
    name: "Joans Birthday",
    photos: [
      "https://images.pexels.com/photos/5821482/pexels-photo-5821482.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2650090/pexels-photo-2650090.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },
  {
    name: "Elegushi Beach",
    photos: [
      "https://images.pexels.com/photos/2743754/pexels-photo-2743754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1684304/pexels-photo-1684304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
    ],
  },

  {
    name: "Kaduna",
    photos: [
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
      "https://images.pexels.com/photos/2743754/pexels-photo-2743754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1684304/pexels-photo-1684304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },

  {
    name: "France",
    photos: [
      "https://images.pexels.com/photos/28642591/pexels-photo-28642591/free-photo-of-casual-portrait-with-soft-light-and-shadow.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2743754/pexels-photo-2743754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1684304/pexels-photo-1684304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },

  {
    name: "Ilorin",
    photos: [
      "https://images.pexels.com/photos/13630172/pexels-photo-13630172.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2/free-photo-of-casual-portrait-with-soft-light-and-shadow.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/2743754/pexels-photo-2743754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1684304/pexels-photo-1684304.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4350057/pexels-photo-4350057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
    ],
  },
  {
    name: "Bash",
    photos: [
      "https://images.pexels.com/photos/17901566/pexels-photo-17901566/free-photo-of-shirtless-man-runs-in-forest.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/28050014/pexels-photo-28050014/free-photo-of-african-woman-on-a-street-market.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1790393/pexels-photo-1790393.jpeg?",
      "https://images.pexels.com/photos/3770674/pexels-photo-3770674.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    ],
  },
  {
    name: "Anita",
    photos: [
      "https://images.pexels.com/photos/20094712/pexels-photo-20094712/free-photo-of-woman-in-a-white-outfit-and-unbuttoned-shirt-standing-on-a-rocky-coat.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/28642591/pexels-photo-28642591/free-photo-of-casual-portrait-with-soft-light-and-shadow.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/4536190/pexels-photo-4536190.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.pexels.com/photos/1102341/pexels-photo-1102341.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      "https://images.unsplash.com/photo-1506277886164-e25aa3f4ef7f?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ],
  },
];

export const names = [
  "Priscilla",
  "Bash",
  "Nathan",
  "Sophia",
  "Victoria",
  "Michael",
  "Isabella",
  "Benjamin",
  "Olivia",
  "William",
  "Charlotte",
  "James",
  "Amelia",
  "Alexander",
  "Grace",
  "Elijah",
  "Hannah",
  "Jacob",
  "Madeline",
  "Henry",
  "Samuel",
  "Lillian",
  "Lucas",
  "Emily",
  "Sebastian",
  "Natalie",
  "Eleanor",
  "Christopher",
  "Elizabeth",
  "Jonathan",
  "Caroline",
  "Matthew",
  "Rebecca",
  "Daniel",
  "Abigail",
  "Zachary",
  "Evelyn",
  "Gabriel",
  "Audrey",
  "Joseph",
  "Victoria",
  "Charles",
  "Mackenzie",
  "Thomas",
  "Penelope",
  "Aiden",
  "Allison",
  "Ryan",
  "Samantha",
  "Jack",
  "Madison",
];
